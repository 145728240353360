import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

function SingupF(props) {
  return (
    <div className="py-5 px-4 mx-4">
		<div className="">
			<img height="128" src="" className="logo-login"/>
			<h2 className='text-center'>Crear cuenta</h2>
		</div>

        <form onSubmit={submitF} id='form'>
            <p id='err'></p>
            <div className='mt-3 mb-1'>
                <label htmlFor='name'>Nombre completo</label>
                <input type='text' name='name' className='form-control' id='name' placeholder='Nombre y apellidos' />
            </div>
            <div className='mt-3 mb-1'>
                <label htmlFor='email'>Correo electr&#243;nico</label>
                <input type='text' className='form-control' name='email' id='email' placeholder='example@domain.com' />
            </div>
            <div className='mt-3 mb-1'>
                <label>Contrase&#241;a</label>
                <input type='password' name='password' className='form-control' id='password'/></div>
            <div className='mt-3 mb-1'>
                <label>Confirma tu contrase&#241;a</label>
                <input type='password' name='password2' className='form-control' id='password2'/>
            </div>
            <button type ='button' className='btn btn-primary w-100 mt-3 rounded-0' onClick='sendSignUp()'>Crear cuenta</button>
		</form>

        <p className='text-center'>
        <Link to='/auth/login'>Ya tienes una cuenta?</Link>
        </p>
    </div>
  )
  function submitF(e){
    e.preventDefault();
  }
}

SingupF.propTypes = {}

export default SingupF
