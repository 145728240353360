import React from 'react'
import PropTypes from 'prop-types'

function HomePage(props) {
  return (
    <div className='container my-3'>
      <h2 className='text-secondary'>NOMBRE DE LA EMPRESA</h2>
      <p>RFC de la empresa</p>

      <div></div>
    </div>
  )
}

HomePage.propTypes = {}

export default HomePage
