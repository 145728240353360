export class ConfigPortal{
    logo = "";
    empresa = "";
    razon = "";
    regimenFiscal = "";
    correo = "";
    slogan = "";
    theme = {
        primary: "",
        secondary: "",
        background: "",
        round: 0,

    };
    constructor(){
    }
}