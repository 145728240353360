import React from 'react'
import PropTypes from 'prop-types'
import { Cliente } from '../../Models/Cliente.class'

function ClienteRow({cliente}) {
  return (
    <tr>
        <td>{cliente.RFC}</td>
        <td>{cliente.Nombre}</td>
        <td>{cliente.CP}</td>
        <td>{cliente.Regimen}</td>
        <td>{cliente.UsoCFDI}</td>
        <td>{cliente.Correo}</td>
        <td>Acciones</td>
    </tr>
  )

 
}

ClienteRow.propTypes = {
    cliente: PropTypes.instanceOf(Cliente).isRequired
}

export default ClienteRow
