import React from 'react'
import {Routes, Route} from 'react-router-dom'
import PropTypes from 'prop-types'
import LoginF from '../../Components/Forms/LoginF'
import '../../Styles/Login.sass'
import SingupF from '../../Components/Forms/SingupF'

function Auth({setUser}) {
  return (
    <div className='d-flex login'>
      <div className='img-section col p-0 m-0'>
      </div>
      <div className='login-section col-12 col-md-6 col-lg-5 col-xl-4"'>
        <Routes>
          <Route path="login" element={<LoginF setUser={setUser}/>}></Route>
          <Route path="signup" element={<SingupF/>}></Route> 
          <Route path="*" element={<LoginF setUser={setUser}/>}></Route>
        </Routes>
      </div>
    </div>
  )
}
Auth.propTypes = {
  setUser: PropTypes.func
}

export default Auth
