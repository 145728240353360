import React from 'react'
import PropTypes from 'prop-types'
import FacturasListC from '../Components/Containers/FacturasListC'

function FacturasPage(props) {
  return (
    <div className='container my-3'>
    <h5 className='text-secondary'>FACTURAS</h5>
    <div className='my-3'>
      <FacturasListC></FacturasListC>
    </div>
    <div>{props.children}</div>
</div>
  )
}

FacturasPage.propTypes = {}

export default FacturasPage
