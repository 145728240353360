import React from 'react'
import PropTypes from 'prop-types'
import TicketListC from '../Components/Containers/TicketListC'
function TicketsPage(props) {
  return(
    <div className='container my-3'>
        <h5 className='text-secondary'>TICKETS</h5>
        <div className='my-3'>
          <TicketListC></TicketListC>
        </div>
        <div>{props.children}</div>
    </div>
  );
}

TicketsPage.propTypes = {}

export default TicketsPage
