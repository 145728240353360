import React from 'react'
import PropTypes from 'prop-types'
import { Ticket } from '../../Models/Ticket.class'
import { TICKETSTATE } from '../../Models/TicketState.enum'

function TicketRow({ticket}) {
  return (
    <tr>
        <td>{ticket.fecha}</td>
        <td>{ticket.numeroTicket}</td>
        <td>{ticket.monto}</td>
        <td>{pillEstado()}</td>
        <td>Acciones</td>
    </tr>
  )

  function pillEstado() {
    switch (ticket.estado){
        case TICKETSTATE.COBRADO :
            return <span className="badge rounded-pill text-bg-primary">Cobrado</span>
        case TICKETSTATE.CANCELADO:
            return <span className="badge rounded-pill text-bg-danger">Cancelado</span>
        case TICKETSTATE.FACTURADO:
            return <span className="badge rounded-pill text-bg-success">Facturado</span>
        default:
            return <span></span>
    }
  }
}

TicketRow.propTypes = {
    ticket: PropTypes.instanceOf(Ticket).isRequired
}

export default TicketRow
