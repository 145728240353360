import React from 'react'
import PropTypes from 'prop-types'
import { Factura } from '../../Models/Factura.class'

function FacturaRow({factura}) {
  return (
    <tr>
        <td>{factura.Fecha}</td>
        <td>{factura.Folio}</td>
        <td>{factura.Rfc}</td>
        <td>{factura.Nombre}</td>
        <td>{factura.Total}</td>
        <td>{factura.Estado}</td>
        <td>Acciones</td>
    </tr>
  )
}

FacturaRow.propTypes = {
    factura: PropTypes.instanceOf(Factura).isRequired
}

export default FacturaRow
