import { REGIMEN } from "./Regimen.enum";
import { UsoCFDI } from "./UsoCFDI.enum";
export class Cliente {
    RFC = "";
    Nombre = "";
    CP = "";
    Regimen = REGIMEN[601];
    UsoCFDI = UsoCFDI.G03;
    Correo = "";

    constructor (RFC, Nombre, CP, Regimen, UsoCFDI, Correo){
        this.RFC = RFC;
        this.Nombre = Nombre;
        this.CP = CP;
        this.Regimen = Regimen;
        this.UsoCFDI = UsoCFDI;
        this.Correo = Correo;
    }
}