import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Ticket } from '../../Models/Ticket.class'
import { TICKETSTATE } from '../../Models/TicketState.enum'
import TicketRow from '../Pure/TicketRow'

function TicketListC(props) {
	let elements = [];
	elements.push(new Ticket("1235",12,"2023-12-05",TICKETSTATE.COBRADO));
	elements.push(new Ticket("1236",30.45,"2023-12-07",TICKETSTATE.CANCELADO));
	elements.push(new Ticket("1234",234.45, "2023-11-11",TICKETSTATE.FACTURADO));

	const [Tickets, setTickets] = useState(elements)
  return (
    <div className='p-3'>
		<table className="table">
			<thead>
				<tr>
					<th>Fecha</th>
					<th>Número de ticket</th>
					<th>Monto</th>
					<th>Estado</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{
					Tickets.map((ticket,key) => {
						return <TicketRow ticket={ticket} key={key}></TicketRow>
					})
				}
			</tbody>
		</table>
    </div>
  )

}

TicketListC.propTypes = {}

export default TicketListC;
