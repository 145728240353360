import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FacturaRow from '../Pure/FacturaRow';
import { Factura } from '../../Models/Factura.class';
import { TICKETSTATE } from '../../Models/TicketState.enum';

function FacturasListC(props) {
	let elements = [];
	elements.push(new Factura("12-10-2023","1234","SADASDASDAD","SDFSDF",123,TICKETSTATE.FACTURADO, "PDF.X","XML.X"));
    elements.push(new Factura("12-10-2023","1234","SADASDASDAD","SDFSDF",123,TICKETSTATE.FACTURADO, "PDF.X","XML.X"));
    elements.push(new Factura("12-10-2023","1234","SADASDASDAD","SDFSDF",123,TICKETSTATE.FACTURADO, "PDF.X","XML.X"));
    elements.push(new Factura("12-10-2023","1234","SADASDASDAD","SDFSDF",123,TICKETSTATE.FACTURADO, "PDF.X","XML.X"));

	const [facturas, setFacturas] = useState(elements);
  return (
    <div className='p-3'>
		<table className="table">
			<thead>
				<tr>
					<th>Fecha</th>
					<th>Folio</th>
					<th>RFC</th>
					<th>Nombre del cliente</th>
					<th>Total</th>
                    <th></th>
                    <th></th>
				</tr>
			</thead>
			<tbody>
				{
					facturas.map((factura,key) => {
						return <FacturaRow factura={factura} key={key}></FacturaRow>
					})
				}
			</tbody>
		</table>
    </div>
  )
}

FacturasListC.propTypes = {}

export default FacturasListC;
