import React from 'react'
import PropTypes from 'prop-types'

function ChangeThemeBtn(props) {
  return (
    <button onClick={ch}>Cambiar tono</button>
  )
  function ch(ref){
	document.getElementById("body").classList.toggle("darkTheme");
  }
}

ChangeThemeBtn.propTypes = {}

export default ChangeThemeBtn
