import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ClienteRow from '../Pure/ClienteRow';
import { Cliente } from '../../Models/Cliente.class';
import { REGIMEN } from '../../Models/Regimen.enum';
import { UsoCFDI } from '../../Models/UsoCFDI.enum';

function ClientesListC(props) {
	let elements = [];
	elements.push(new Cliente("SDJFHSJDFHJDFH","Asociados","86404",REGIMEN[601],UsoCFDI.D01,"example.js"));
    elements.push(new Cliente("SDJFHSJDFHJDFH","Asociados","86404",REGIMEN[601],UsoCFDI.D01,"example.js"));
    elements.push(new Cliente("SDJFHSJDFHJDFH","Asociados","86404",REGIMEN[601],UsoCFDI.D01,"example.js"));
    elements.push(new Cliente("SDJFHSJDFHJDFH","Asociados","86404",REGIMEN[601],UsoCFDI.D01,"example.js"));

	const [clientes, setClientes] = useState(elements);
  return (
    <div className='p-3'>
		<table className="table">
			<thead>
				<tr>
					<th>RFC</th>
					<th>Nombre</th>
					<th>C.P.</th>
					<th>Régimen Fiscal</th>
					<th>Uso de CFDI</th>
                    <th>Correo</th>
                    <th></th>
				</tr>
			</thead>
			<tbody>
				{
					clientes.map((cliente,key) => {
						return <ClienteRow cliente={cliente} key={key}></ClienteRow>
					})
				}
			</tbody>
		</table>
    </div>
  )
}

ClientesListC.propTypes = {}

export default ClientesListC;
