import React from 'react'
import PropTypes from 'prop-types'

function NotFound(props) {
  return (
    <div>NotFound</div>
  )
}

NotFound.propTypes = {}

export default NotFound
