import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

function LoginF() {
	const [error, setError] = useState('');

  return (
    <div className="py-5 px-4 mx-4">
			<div className="">
				<img height="128" src="" className="logo-login"/>
				<h2 className='text-center'>Iniciar sesión</h2>
			</div>
			
			<form onSubmit={submit} id='form' className='mt-3'>

				{error == "v" && errorV()}
				{error == "t" && errorT()}

				<div>
					<label htmlFor="user" >Nombre de usuario o correo</label>
					<input type="text"  className="form-control " name="user" id="user" placeholder="usuario o email" autoComplete="username" />
				</div>
				<div className="mt-3 mb-1">
					<label htmlFor="password">Contraseña</label>
					<input type="password" className="form-control" name="password" id="password" autoComplete="current-password"/>
				</div>
				
				<p className="text-end"><a href="/auth/sendreset.aspx" >Olvidé mi contraseña </a></p>
				<input type="checkbox" className="form-check-input" name="remember" id="remember"/>
				<label htmlFor="remember">Recu&#233;rdame</label>
				<br/>
				<br /><button className="btn btn-primary" type="button" onClick="sendLogin()">Iniciar sesi&#243;n</button>
			</form>
			<br/>
			<Link to="/auth/signup" className="d-block w-100 text-center">Aun no tienes cuenta </Link>
		
		<button onClick={() =>{setError("v")}}> Set error v</button>
		<button onClick={() =>{setError("t")}}> Set error t</button>
    </div>
  )

  //Elements
  function errorV () {
	return (
		<form action='/auth/resend.aspx'> 
			<div className='alert alert-danger rounded-0 border-0 m-0 text-center'>
				Esta cuenta no ha sido validada
			</div>
			<button type='submit' className='btn btn-danger m-0' name='a' value='confirm'>
				Reenviar correo
			</button>
		</form>
	)
  }
  function errorT(){
	return <p className='alert alert-danger'>El usuario o contraseña son incorrectos</p>
  }
  function submit(e){
	e.preventDefault();
  }
}

LoginF.propTypes = {}

export default LoginF
