import { TICKETSTATE } from "./TicketState.enum";
import { METODOPAGO } from "./MetodoPago.enum";
export class Ticket{

    fecha = "";
    numeroTicket = "";
    monto = "";
    estado = TICKETSTATE.COBRADO;
    pdf = "";
    xml = "";

    constructor (numeroTicket, monto,  fecha, estado){
        this.numeroTicket = numeroTicket;
        this.monto = monto;
        this.fecha = fecha;
        this.estado = estado;
    }
}