import { TICKETSTATE } from "./TicketState.enum";

export class Factura {
    Fecha = "";
    Folio = "";
    Rfc = "";
    Nombre = "";
    Total = 0;
    Estado = TICKETSTATE.FACTURADO;
    PdfLink = "";
    XmlLink = "";

    constructor (fecha, folio, rfc, nombre, total, estado, pdf, xml){
        this.Fecha = fecha;
        this.Folio = folio;
        this.Rfc = rfc;
        this.Nombre = nombre;
        this.Total = total;
        this.Estado = estado;
        this.PdfLink = pdf;
        this.XmlLink = xml;
    }
}