import React from 'react'
import PropTypes from 'prop-types'
import ClientesListC from '../Components/Containers/ClientesListC'

function ClientesPage(props) {
  return (
    <div className='container my-3'>
      <h5 className='text-secondary'>CLIENTES</h5>
      <div className="my-3">
        <ClientesListC></ClientesListC>
      </div>
      <div>{props.children}</div>
    </div>
  )
}

ClientesPage.propTypes = {}

export default ClientesPage
