import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import { ConfigPortal } from '../Models/ConfigPortal.class';
import '../Styles/ConfigPage.sass'

function ConfigPage(props) {
  let con = new ConfigPortal();

  const empresaName = useRef('')

  const [first, setfirst] = useState(con);

  return (
    <div className='my-3 container'>
      <h5 className='text-secondary'>CONFIGURACIÓN</h5>
      <div className='d-flex'>
        <div className='col-12 col-md-7 mt-3 p-4 '>
          <form action="" onSubmit={submit}>
            <div className='mb-3'>
              <input ref={empresaName} type="text" className="empresaI" id='empresaName' onInput={()=>{empresaName.current.value = empresaName.current.value.toUpperCase()}}/>
              <label className='small text-secondary'>Nombre de la empresa</label>
            </div>
            <div className='d-flex flex-wrap'>
              <label htmlFor="" className='col-4'>Razón social</label>
              <input type="text" className='col-8'/>

              <label htmlFor="" className='col-4'>Regimen fiscal</label>
              <select className='col-8' >
                <option value="">a</option>
              </select>
              
              <label htmlFor="" className='col-4'>Correo</label> 
              <input type="email" className='col-8'/>
              
              <label htmlFor="" className='col-4'>Slogan</label>
              <input type="text" className='col-8'/>

              <div className='col-12'>
                <label htmlFor="" className='col-4'>Color 1</label>
                <input type="color" />
              </div>
              <div className='col-12'>
                <label htmlFor="" className='col-4'>Color 2</label>
                <input type="color" />
              </div>


              <label htmlFor="" className='col-4'>Logo</label>
              <input type="file" className='col-8'/>

              <label htmlFor="" className='col-4'>Certificado</label>
              <input type="file" className='col-8'/>
              
              <label htmlFor="" className='col-4'>Key</label>
              <input type="file"  className='col-8'/>
              <label htmlFor="" className='col-4'>Contraseña</label>
              <input type="password" className='col-8' />
              <button type='submit' className='btn btn-primary'>Guardar</button>
            </div>
          </form>
        </div>
        <div className='col-12 col-md-5 p-4'>
          <h5>PREVIEW</h5>
          <div>

          </div>
        </div>
      </div>
    </div>
  )
  function submit(e){
    e.preventDefault();

  }
}

ConfigPage.propTypes = {}

export default ConfigPage
