import './Styles/App.css';
import { Route, Routes, useNavigate} from "react-router-dom";
import Menu from './Components/Containers/Menu';
import TicketsPage from './Pages/TicketsPage';
import HomePage from './Pages/HomePage';
import ClientesPage from './Pages/ClientesPage';
import FacturasPage from './Pages/FacturasPage';
import ConfigPage from './Pages/ConfigPage';
import UserPage from './Pages/UserPage';
import NotFound from './Pages/404/NotFound';
import { useEffect, useState} from 'react';

function App() {

  const [user, setUser ] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    auth()
  })

  
  return (
    
    <div className='app'>
    <Menu></Menu>
    <Routes>
      <Route path="/" element={<HomePage/>}></Route>
      <Route path="/tickets" element={<TicketsPage/>}></Route>
      <Route path='/clientes' element={<ClientesPage/>}></Route>
      <Route path='/facturas' element={<FacturasPage/>}></Route>
      <Route path='/configuracion' element={<ConfigPage/>}></Route>
      <Route path='/user' element={<UserPage></UserPage>}></Route>
      <Route path='*' element={<NotFound/>}></Route>
    </Routes>
        
    </div>
  );

  function auth(){
    // let user = getCookie("user")
    // if (user == null){
    //   setUser(null);
    //   navigate("/auth");
    // }else{
    // setUser(user);
    // }
  }
  function getCookie(name) 
    {
      var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
     if ( match ) return match[2] 
     else return null;
    }
}

export default App;
