import React from 'react'
import PropTypes from 'prop-types'
import ChangeThemeBtn from '../Components/Pure/ChangeThemeBtn'

function UserPage(props) {
  return (
    <div>
    <h5 className='text-secondary'>CUENTA</h5>
    <ChangeThemeBtn></ChangeThemeBtn></div>
  )
}

UserPage.propTypes = {}

export default UserPage
