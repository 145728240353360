import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { ReactComponent as HomeLogo } from '../../Media/icons/home.svg';
import { ReactComponent as MELogo } from '../../Media/icons/me.svg';
import { ReactComponent as ClienteLogo } from '../../Media/icons/cliente.svg';
import { ReactComponent as ProductoLogo} from '../../Media/icons/productos.svg';
import { ReactComponent as ComprobantesLogo } from '../../Media/icons/listado.svg';
import { ReactComponent as NComprobantesLogo } from '../../Media/icons/fe.svg';
import { ReactComponent as UserLogo } from '../../Media/icons/cliente.svg';
import '../../Styles/Menu.sass'
function Menu(props) {
  return (
    <header className="sticky-top minimal-box-shadow border-bottom ">
	<nav className="d-flex w-100 flex-shrink-0 p-0" style= {{height:'fit-content'}}>
		<Link className="menu-element" to="/">
			<i><HomeLogo className="icon-primary"></HomeLogo></i>
		</Link>
		<div className="scrollmenu ps-3 flex-shrink-0">
			<Link className="menu-element" to="/tickets">
				<i><ProductoLogo className="icon-primary"></ProductoLogo></i>
				Tickets
			</Link>
			<Link className="menu-element" to='/clientes'>
				<i><ClienteLogo className="icon-primary"></ClienteLogo></i>
				Clientes
			</Link>
			<Link className="menu-element" to='/facturas'>
				<i><ComprobantesLogo className="icon-primary"></ComprobantesLogo></i>
				Facturas
			</Link>
			<Link className="menu-element" to="/configuracion">
				<i><NComprobantesLogo className="icon-primary"></NComprobantesLogo></i>
				Configuración
			</Link>
		</div>
		<div className="d-flex">
			<Link className="menu-element" to="/user">
			<i><UserLogo className="icon-primary"></UserLogo></i>
		</Link>
		</div>
		
	</nav>
    </header>
  )

}

Menu.propTypes = {}

export default Menu
